<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ name: 'index' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{name: 'message.check'}">微信通知 - 核销通知</el-breadcrumb-item>
      <el-breadcrumb-item> {{ info.name }}详情页</el-breadcrumb-item>
    </el-breadcrumb>

    <el-row :gutter="12">
      <el-col :span="24">
        <br>
        <!----- 搜索框 start------->
        <el-form :inline="true" class="search">
          <el-form-item label="状态搜索">
            <el-select v-model="searchData.status" placeholder="状态搜索">
              <el-option label="已发送" :value="2"></el-option>
              <el-option label="未发送" :value="1"></el-option>
              <el-option label="待审核" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时间">
            <el-date-picker
                v-model="searchData.publish_at"
                editable
                clearable
                value-format="YYYY-MM-DD"
                type="date"
                placeholder="选择日期"
            />
          </el-form-item>
          <el-form-item>
            <el-button @click="getCheckImages()">查询</el-button>
          </el-form-item>

          <el-form-item style="float: right">
            <el-button type="primary" @click.stop="clickUpload()">上传图片</el-button>
          </el-form-item>
        </el-form>
        <!----- 搜索框 end------->
      </el-col>
      <el-col style="text-align: center;" v-show="tableData.length < 1">
        <br/><br/>
        还没有上传数据
      </el-col>
      <el-col v-for="(item, index) in tableData" :key="index" :span="6" style="margin-top: 12px;text-align:center;">
        <el-card :body-style="{ padding: '10px' }">
          <img height="100" :src="item.image_url" class="image"/>
          <div style="padding: 14px">
            <div v-text="proxy.status[item.status]"></div>
            <div v-text="item.publish_at"></div>
            <div class="bottom">
              <el-button v-show="item.status === 0" size="small" @click="auditImage(item.id)">审核</el-button>
              <el-button v-show="item.status > 0" size="small" @click="markImage(item.id, item.status)">标记</el-button>
              <el-button v-show="item.status === 1" size="small" @click="sendImage(item.id)">发送</el-button>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <!----- 分页 start------->
    <div class="cherry-page" v-show="pageData.total">
      <el-pagination
          small
          background
          prev-text="上一页"
          next-text="下一页"
          :page-size="searchData.per_page"
          v-model:current-page="searchData.page"
          @update:current-page="getCheckImages()"
          layout="prev, pager, next, total"
          :total="pageData.total"
          class="mt-4"
      />
    </div>
    <!----- 分页 end------->


    <!-------- 弹出框表单 start ---------->
    <el-dialog
        v-model="dialog.upload"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="上传图片">

      <el-upload
          multiple
          v-model:file-list="formData.data.lists"
          list-type="picture-card"
          accept="image/jpeg,image/png,image/jpg"
          :http-request="uploadFile"
      >
        上传图片
      </el-upload>

      <el-input type="number" v-model="formData.data.number" placeholder="输入每单的券数量"></el-input>

      <template #footer>
              <span class="dialog-footer">
                <el-button type="primary" @click="onUploadSubmit">提交图片</el-button>
              </span>
      </template>
    </el-dialog>
    <!-------- 弹出框表单 end ---------->
  </div>
</template>

<script>
import {apiGetCheck, apiGetCheckImages, apiPostCheckImages, apiPostMarkCheckImage, apiPostAuditCheckImage, apiPostSendCheckImage} from '@/api/wechatCheckApi'
import {apiGetQiniuToken} from "@/api/commonApi"
import randomstring from 'randomstring'
import * as qiniu from 'qiniu-js'

export default {
  data() {
    return {
      proxy: {
        status: {
          0: '待审核',
          1: '待发送',
          2: '已发送'
        }
      },
      tableData: [],
      searchData: {
        status: '',
        per_page: 8,
        page: 1,
        publish_at: '',
        task_id: '',
      },
      formData: {
        data: {
          number: 1,
          images: [],
          lists: [],
        }
      },
      pageData: {
        total: 0,
      },
      info: {
        name: '',
      },
      dialog: {
        upload: false
      }
    }
  },
  created() {
    this.getCheckInfo()
    this.getCheckImages()
    this.searchData.task_id = this.$route.query.task_id
  },
  methods: {
    // 获取详情
    getCheckInfo() {
      apiGetCheck(this.$route.params.id).then((data) => {
        this.info = data
      })
    },
    sendImage (id) {
      apiPostSendCheckImage(id).then(() => {
        this.$message.success('操作成功')
        this.getCheckImages()
      })
    },
     // 审核图片
    auditImage (id) {
      apiPostAuditCheckImage(id).then(() => {
        this.$message.success('审核成功')
        this.getCheckImages()
      })
    },
    // 标记图片
    markImage(id, status) {
      let message = status === 1 ? '已发送' : '未发送';
      this.$messageBox.confirm(`确定标记为 ${message} 吗?`, '标记', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
      }).then(() => {
        apiPostMarkCheckImage(id).then(() => {
          this.$message.success('标记成功')
          this.getCheckImages()
        })
      })
    },
    // 获取图片列表
    getCheckImages() {
      apiGetCheckImages(this.$route.params.id, this.searchData).then((res) => {
        this.tableData = res.data
        this.pageData = res.meta
      })
    },
    // 图片上传
    uploadFile(file) {
      let ext = file.file.name.substr(file.file.name.lastIndexOf(".") + 1)
      let name = randomstring.generate(16)
      let path = `check/${this.$route.params.id}-${name}.${ext}`

      apiGetQiniuToken({path: path}).then(data => {
        const observable = qiniu.upload(file.file, path, data.token)

        observable.subscribe(null, (err) => {
          console.log(err)
        }, (success) => {
          this.formData.data.images.push(data.domain + success.key)
          file.onSuccess({
            name: name,
            url: data.domain + success.key
          })
        })
      })
    },
    // 提交图片信息
    onUploadSubmit() {
      if (this.formData.data.images.length <= 0) {
        this.$message.info('请上传图片')
        return
      }

      apiPostCheckImages(this.$route.params.id, {
        images: this.formData.data.images,
        number: this.formData.data.number
      }).then(() => {
        this.getCheckImages()
        this.closeUploadDialog()
        this.formData.data.images = []
        this.formData.data.lists = []
      })
    },
    // 传图片弹框
    clickUpload() {
      this.showUploadDialog()
    },
    showUploadDialog() {
      this.dialog.upload = true
    },
    closeUploadDialog() {
      this.dialog.upload = false
    },
  }
}
</script>
